// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, Navigation } from "swiper";

const HERO = document.querySelectorAll(".swiper-container--carousel");

// init swiper
if (HERO.length > 0) {
    HERO.forEach(CAROUSEL => {
        if (CAROUSEL.querySelectorAll(".swiper-slide").length > 1) {
            new Swiper (CAROUSEL, {
                modules: [Autoplay, Navigation],
                autoplay: {
                    delay: 7500,
                },
                slidesPerView: 2,
                spaceBetween: 18,
                loop: true,
                navigation: {
                    nextEl: CAROUSEL.querySelector(".swiper-button--next"),
                    prevEl: CAROUSEL.querySelector(".swiper-button--prev"),
                },
                speed: 150,
                breakpoints: {
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 35
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    // when window width is >= 400px
                    400: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    }
                }
            });
        }
    });
}


/*
breakpoints: {
    // when window width is >= 1024px
    1024: {
      slidesPerView: 6,
      spaceBetween: 35
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    // when window width is >= 400px
    400: {
      slidesPerView: 3,
      spaceBetween: 40
    }
}
  */
